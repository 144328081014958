import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { router } from "@inertiajs/react";
import { Icon } from "@/components/Icon";
import { LoginForm } from "@/components/forms/login-form";

type registerProviders = "Discord" | "VATSIM" | "email" | null;

const AuthForm = () => {
  const [method, setMethod] = useState<registerProviders>(null);

  return (
    <>
      <div className="flex flex-col space-y-2 text-center">
        <div className="relative">
          <Button
            size="icon"
            variant="ghost"
            onClick={() => setMethod(null)}
            className={method !== "email" ? "hidden" : "absolute left-0"}
          >
            <Icon icon={"fa-arrow-left"} />
          </Button>
          <h1 className="text-2xl font-semibold tracking-tight">
            Sign in to continue
          </h1>
        </div>

        <p className="text-sm">
          {method === "email"
            ? "Please fill out your email to continue."
            : "Choose a sign in method below."}
        </p>
      </div>
      <div className="flex flex-col gap-8 text-center mb-4">
        {method === null && <Switcher setMethod={setMethod} />}
        {method === "email" && <LoginForm />}
        {method !== "email" && method !== null && (
          <Button className="w-full" type="button" disabled>
            <Icon icon={"fa-spinner-third fa-spin"} className="mr-2" />
            Redirecting to {method}
          </Button>
        )}
      </div>
    </>
  );
};

type SwitcherProps = {
  setMethod: (method: registerProviders) => void;
};

const Switcher = (props: SwitcherProps) => {
  const variant = "Sign in";

  return (
    <div>
      <div className="flex flex-col gap-3">
        <Button
          className="w-full"
          onClick={() => {
            props.setMethod("Discord");
            router.get(route("auth.provider.request", { provider: "discord" }));
          }}
        >
          <Icon icon={"fa-brands fa-discord"} className="mr-2" />
          {variant} with Discord
        </Button>
        <Button
          className="w-full"
          variant="secondary"
          onClick={() => {
            props.setMethod("VATSIM");
            router.get(route("auth.provider.request", { provider: "vatsim" }));
          }}
        >
          {variant} with VATSIM
        </Button>
        <Button
          className="w-full"
          variant="secondary"
          onClick={() => props.setMethod("email")}
        >
          {variant} with Email
        </Button>
      </div>
    </div>
  );
};

export { AuthForm };
