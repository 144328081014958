import { Link, router } from "@inertiajs/react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Icon } from "@/components/Icon";
import React, { useState } from "react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const LoginForm = () => {
  const [magicEmailSent, setMagicEmailSent] = useState(false);

  const loginFormSchema = z.object({
    email: z
      .string({ required_error: "Please enter your email" })
      .email({ message: "Please enter a valid email" }),
    password: z
      .string({ required_error: "Please enter your password" })
      .optional(),
  });

  type LoginFormData = z.infer<typeof loginFormSchema>;

  const form = useForm<LoginFormData>({
    resolver: zodResolver(loginFormSchema),
  });

  const values = useWatch({ control: form.control });

  const login = async (values: LoginFormData) => {
    try {
      if (values.password === "") {
        await window.axios.post(route("login.magic.request"), values);
        setMagicEmailSent(true);
        return;
      }
      await new Promise((resolve, reject) => {
        router.post(route("login.process"), values, {
          onStart: () => {
            form.clearErrors();
          },
          onSuccess: () => {
            resolve(() => {
              router.replace(route("dashboard"));
            });
          },
          onError: (errors) => {
            reject(errors);
          },
        });
      });
    } catch (errors) {
      setMagicEmailSent(false);
      console.log(errors);
      const errorMap = Object.entries(errors as Record<string, string>);
      errorMap.map((error) => {
        form.setError(error[0] as "email" | "password", {
          type: "manual",
          message: error[1],
        });
      });
      form.setValue("password", "");
    }
  };

  if (magicEmailSent) {
    return (
      <div className="text-left">
        <Alert>
          <AlertTitle>Email sent!</AlertTitle>
          <AlertDescription>
            {
              "We've sent you a link to sign in. Please check your inbox and click on the link to continue."
            }
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="text-left">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(login)} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            defaultValue=""
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col">
                  <FormLabel className="text-body-text-default">
                    Email
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      autoFocus={!values.email}
                      autoComplete={"email webauthn"}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="password"
            defaultValue=""
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col">
                  <FormLabel className="text-body-text-default">
                    Password (optional)
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      autoFocus={!!values.email}
                      autoComplete={"current-password webauthn"}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />

          {!values.password ? (
            <Button
              className="w-full"
              type="submit"
              disabled={form.formState.isSubmitting || magicEmailSent}
            >
              {!form.formState.isSubmitting ? (
                "Send magic link"
              ) : (
                <Icon icon={"fa-spinner-third fa-spin"} />
              )}
            </Button>
          ) : (
            <Button className="w-full" type="submit">
              Login
            </Button>
          )}
          <div className="flex justify-end">
            <Button variant="link" className="p-0" asChild>
              <Link href={route("password.request")}>Forgot Password?</Link>
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export { LoginForm };
